import Banner from "@/Components/Catalyst/Banner";
import { Button } from "@/Components/Catalyst/button";
import LandingBar from "@/Components/Catalyst/LandingBar";
import {
    Listbox,
    ListboxLabel,
    ListboxOption,
} from "@/Components/Catalyst/listbox";
import { FrequentlyAskedQuestionsAccordion } from "@/Components/Faq";
import { FlipWords } from "@/Components/FlipWords";
import Footer from "@/Components/Footer";
import LandingCTA from "@/Components/LandingCTA";
import LandingFeatures from "@/Components/LandingFeatures";
import { Reviews } from "@/Components/Reviews";
import { Link, Head } from "@inertiajs/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react"; 

export default function Welcome({ auth, laravelVersion, phpVersion }) {
    const flipWords = [
        "faster",
        "safer",
        "smarter",
        "better",
    ];

    const reviews = [
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
    ];

    const FAQs = [
        {
            question: "Why My Place?",
            answer: "My Place is your modern alternative to traditional rental marketplaces. We harness the power of AI and biometric technology to make your rental experience more efficient and secure, reducing the risks often associated with renting a property.",
        },
        {
            question: "Are there any fees?",
            answer: "Absolutely not! Browsing and securing apartments on My Place is entirely free. Connect directly with landlords to schedule viewings and sign contracts—no hidden costs or intermediaries involved.",
        },
        {
            question: "⁠⁠How do I know the listing is legitimate?",
            answer: "We prioritize your safety above all else. All listings on our platform are manually verified to minimize the risk of scams. If you come across a listing that seems suspicious, please notify us at [support@myplace.casa](mailto:support@myplace.casa), and we'll investigate it right away.",
        },
        {
            question:
                "⁠What should i do if I have a problem with the landlord?",
            answer: "Your peace of mind is important to us. If you experience any issues with a landlord, please reach out to us at [support@myplace.casa](mailto:support@myplace.casa). Our team is here to help resolve any problems you may encounter.",
        },
        {
            question: " ⁠What if I don’t speak Spanish?",
            answer: "Language barriers are not a problem! Our chat system features a built-in translation function that seamlessly translates messages from any language to English. Communicate effortlessly with landlords, regardless of the language they speak.",
        },
    ];

    const [isWrapped, setIsWrapped] = useState(false);
    const flatRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (flatRef.current) {
                const flatPosition = flatRef.current.getBoundingClientRect();
                const h1Position =
                    flatRef.current.parentElement.getBoundingClientRect();

                setIsWrapped(flatPosition.top > h1Position.top);
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const showBanner = useFeatureFlagEnabled("show_banner");

    return (
        <>
            <Head title="Welcome" />
            <div className="text-black bg-gray-50 dark:bg-black dark:text-white">
                {showBanner && <Banner />}
                <LandingBar auth={auth} />
                <main>
                    <div className="relative isolate">
                        <svg
                            aria-hidden="true"
                            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                        >
                            <defs>
                                <pattern
                                    x="50%"
                                    y={-1}
                                    id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                    width={200}
                                    height={200}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <path d="M.5 200V.5H200" fill="none" />
                                </pattern>
                            </defs>
                            <svg
                                x="50%"
                                y={-1}
                                className="overflow-visible fill-gray-50"
                            >
                                <path
                                    d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                    strokeWidth={0}
                                />
                            </svg>
                            <rect
                                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
                                width="100%"
                                height="100%"
                                strokeWidth={0}
                            />
                        </svg>
                        <div
                            aria-hidden="true"
                            className="absolute top-0 right-0 -ml-24 overflow-hidden left-1/2 -z-10 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
                        >
                            <div
                                style={{
                                    clipPath:
                                        "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
                                }}
                                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#BDC2BA] to-[#C3C9C5] opacity-30"
                            />
                        </div>
                        <div className="overflow-hidden">
                            <div className="px-6 pb-32 mx-auto max-w-7xl pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                                <div className="max-w-2xl mx-auto gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                    <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                            We make finding a{" "}
                                            <span ref={flatRef}>flat</span>
                                            {!isWrapped && <br />}
                                            <FlipWords words={flipWords} />
                                        </h1>
                                        <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                                            We believe in homes, not just
                                            houses. Start your search here.
                                        </p>
                                        <div className="flex items-center mt-10 gap-x-6">
                                            <Button
                                                href={route("register")}
                                                color="white"
                                                className="whitespace-nowrap"
                                            >
                                                Find your next flat in
                                                <Listbox
                                                    name="city"
                                                    defaultValue="madrid"
                                                >
                                                    <ListboxOption value="madrid">
                                                        <ListboxLabel>
                                                            Madrid
                                                        </ListboxLabel>
                                                    </ListboxOption>
                                                    <ListboxOption value="segovia">
                                                        <ListboxLabel>
                                                            Segovia
                                                        </ListboxLabel>
                                                    </ListboxOption>
                                                </Listbox>
                                                &rarr;
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="flex justify-end gap-8 mt-14 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                                        <div className="flex-none pt-32 ml-auto space-y-8 w-44 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="/assets/img/landing-kitchen.jpg"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                        </div>
                                        <div className="flex-none mr-auto space-y-8 w-44 sm:mr-0 sm:pt-52 lg:pt-36">
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="/assets/img/landing-stairs.jpg"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="/assets/img/landing-bbq.jpg"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                        </div>
                                        <div className="flex-none pt-32 space-y-8 w-44 sm:pt-0">
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="/assets/img/landing-wines.jpg"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="/assets/img/landing-outdoors.jpg"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LandingFeatures />
                    {/*<Reviews reviews={reviews} />*/}
                    <FrequentlyAskedQuestionsAccordion FAQs={FAQs} />
                    <LandingCTA />
                    <Footer />
                </main>
            </div>
        </>
    );
}
