import { Button } from "./Catalyst/button";

export default function LandingCTA() {
    return (
        <div className="relative overflow-hidden bg-gray-900 isolate">
            <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Got a property?
                        <br />
                        Find the right tenant with My Place.
                    </h2>
                    <p className="max-w-xl mx-auto mt-6 text-lg leading-8 text-gray-300">
                        My Place is a platform that connects verified landlords with screened tenants
                        looking for a place to rent.
                    </p>
                    <div className="flex items-center justify-center mt-10 gap-x-6">
                        <Button href={route("landlords")} color="teal">
                            Find your next tenant on My Place
                        </Button>
                    </div>
                </div>
            </div>
            <svg
                viewBox="0 0 1024 1024"
                aria-hidden="true"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
            >
                <circle
                    r={512}
                    cx={512}
                    cy={512}
                    fill="url(#8d958450-c69f-4251-94bc-4e091a323369)"
                    fillOpacity="0.7"
                />
                <defs>
                    <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
                        <stop stopColor="#14b8a6" />
                        <stop offset={1} stopColor="#115e59" />
                    </radialGradient>
                </defs>
            </svg>
        </div>
    );
}
